import { connect } from 'react-redux';
import App from '../../components/App';//src/components/App
import { getInfo } from '../../actions'; //src/actions
// isConnected: state.users.isConnected,
//  tags: state.site.tags,
const mapStateToProps = (state) => ({
  accueil: state.home.accueil,
});
 
// fetchAdventureGames: (id) => {
//   dispatch(fetchAdventureGames(id));
// },
// closeBurger: () => {
//   dispatch(closeBurger());
// },
const mapDispatchToProps = (dispatch) => ({
  getInfo: () => {
    dispatch(getInfo());
  },
});
 
export default connect(mapStateToProps, mapDispatchToProps)(App); 
