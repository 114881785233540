/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import React from 'react';
// import PropTypes from 'prop-types';
/*import Menu from 'src/containers/Menu';
import Form from 'src/containers/Form';
import Logo from './Logo';*/
import { useDispatch, useSelector } from 'react-redux'; // useSelector
import ScrollText from './ScrollText';
import './style.scss';
import { Link } from 'react-router-dom';

//import { useSelector } from 'react-redux'
//import './phone-style.scss'; 
//import logo from '../../../assets/logo_med_transpa.png'
const Navbar = () => {
  const accueil = useSelector(state => state.home.accueil)
  //console.log(truc)
  const dispatch = useDispatch()
  accueil.length === 0 && dispatch({ type: "GET_INFO" });
  //const accueil = useSelector(state => state.home.accueil);
  //!accueil && dispatch({ type: "GET_INFO" });
  return (
    <header className="nav">
      <ScrollText />
      <div className='header'>
        <img src="https://www.aht.li/3644366/logo_med_transpa.png" alt="logo de l'association" className="header-logo" />
        <div className="header-links">
          <Link to="/" className="header-links-item">
            Désenfantement
          </Link>
          <Link to="/temoignages" className="header-links-item">
            Témoignages
          </Link>
          <Link to="/marche-de-2018" className="header-links-item">
            La marche
          </Link>
          <Link to="/blog" className="header-links-item">
            Le blog
          </Link>
        </div>
        
      </div>
      
    </header>

  );
};

Navbar.propTypes = {

};

export default Navbar;
