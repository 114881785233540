import {
    STORE_TEMOIGNAGES,
    SELECT_TEMOIGNAGE
} from '../actions';
const initialState = {
    list: [],
    selected : '',
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case STORE_TEMOIGNAGES: {
            return {
                ...state,
                list: action.list
            };
        }
        case SELECT_TEMOIGNAGE: {
            /*
            const selectedTemoignage = state.temoignages.list.map((t) => {
                if (t.msg_id === action.selected) {
                    return t.msg_content;
                } 
                return ''
            })
            
            
            const selectedTemoignage = state.temoignages.list.find( elem => elem.msg_id === action.selected).msg_content
            */
            //console.log(action.toDisp)
            return {
                ...state,
                selected: action.toDisp
            };
        }
        default:
            return state;
    }
};

export default reducer;
