import React from 'react'; // , { useEffect } 
import { useSelector/*, useDispatch*/ } from 'react-redux';
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Home = () => {
  //useEffect(getInfo, [getInfo]);
  //console.log(getInfo)
  //const truc = useSelector(state => state.home.accueil)
  //const dispatch = useDispatch()
  const accueil = useSelector(state => state.home.accueil);
  //accueil.length === 0 && dispatch({ type: "GET_INFO" });
  //console.log(truc)
  /*
  let harey = [truc.phrase1, truc.phrase2, truc.phrase3, truc.phrase4, truc.phrase5];
  let compteur = 0;
  setInterval(function() {
    if (compteur === harey.length-1) {
      compteur = 0;
      document.querySelector('.accueil-slide').innerHTML = `<h3 class='accueil-slide-phrase'>${harey[compteur]}</h3>`;
    } else {
      compteur++
      document.querySelector('.accueil-slide').innerHTML = `<h3 class='accueil-slide-phrase'>${harey[compteur]}</h3>`;
    }
  }, 15*1000);
  */
  return (
    <div className="accueil">
      {/*
        <div className='accueil-slide'>
          <h3 className='accueil-slide-phrase'>{harey[0]}</h3>
        </div>
      */}
      
      <div className='accueil-div'>
        <img className="accueil-div-img" src="https://www.aht.li/3645926/breastfeeding-2730855_960_720.png" alt="img" />
        <div className="accueil-div-desenfantement">
          <h1 className="accueil-div-desenfantement-titre">Qu'est le désenfantement ?</h1>
          <p className="accueil-div-desenfantement-paragraphe">{accueil.paragraphe1}</p>
          <p className="accueil-div-desenfantement-signature">LE DÉSENFANTEMENT EST UN CRIME !</p>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  //getInfo: PropTypes.func.isRequired,
};

export default Home;
