import React from 'react';
//import { Link } from 'react-router-dom';

import './style.scss';

const Adherer = () => {
  return (
    <div className="adherer">
      <iframe title='yapla' width="100%" height="700vh" src="https://maman-est-debout.s2.yapla.com/fr/espace-membres"> </iframe>
    </div>
  );
};

export default Adherer;
