import React from 'react'; // , { useEffect } 
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Marche = ({
  getInfo,
}) => {
  //useEffect(getInfo, [getInfo]);
  //console.log(getInfo)

  //const truc = useSelector(state => state.home.accueil)
  //console.log(truc)
  return (
    <div className="marche">
        {/*iframe yt*/}
        <iframe className='marche-iframe' width="720" height="480" src="https://www.youtube-nocookie.com/embed/FI6MZVnmpxs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
        <div className='marche-articles'>
          <div className='marche-articles-a'>
            <img className='marche-articles-a-img' src='https://www.aht.li/3648028/LHebdo_15_02_2018_P-7.jpeg' alt='Article de presse'/>
            <div className='marche-articles-a-div'>
              <h3>L'Hebdo (Charente-Maritime), page 7, jeudi 15 février 2018, par Marie Ballet</h3>
              <h4>Rochefort, elle marche pour les femmes «désenfantées»</h4>
              <p>Corinne Elsass s’est inspirée de son histoire personnelle pour lancer, le 28 février prochain, une marche à travers la France, pour informer et alerter sur les violences faites aux femmes, en particulier le désenfantement.</p>
              <p>Il y a presque trois ans maintenant, et après de longs mois d’entraînement et de préparatifs, c’est par le biais de la page «Rochefort d’Abord» que Marie Ballet, correspondante de presse à l’Hebdo de Charente-Maritime, prendra contact avec moi. Elle sera en effet la première à entendre l’appel de détresse des mères désenfantées suite à violences conjugales, la première à défendre le sujet du désenfantement, la première à nous publier. Merci Marie Ballet !</p>
            </div>
          </div>
          <div className='marche-articles-b'>
            <img className='marche-articles-b-img' src='https://www.aht.li/3648029/Article_PDF_JPEG.jpg' alt='Article de presse'/>
            <div className='marche-articles-b-div'>
              <h3>Sud-Ouest, Rochefort Agglo, lundi 26 février 2018, par Kharinne Charov :</h3>
              <h4>Elle marche pour revoir ses enfants</h4>
              <p>Initiative : mercredi, Corinne, privée de ses enfants, va s’élancer de Rochefort pour aller jusqu’à Paris afin de dénoncer le désenfantement, pas si rare</p>
              <p>Je rencontre Kharinne Charov deux jours avant le départ de la Marche, au milieu d’un fourmillement de réglages de dernière minute. Elle aussi devra défendre le sujet : à l’époque, les violences conjugales et leurs conséquences ne font pas recette… Au regard de l’actualité, les mentalités seraient-elles en train de changer, d’évoluer, vers une meilleure écoute des victimes ? Trois ans plus tard… Revenons à l’interview de Kharinne pour Sud-Ouest. Je réponds sans détours à toutes les questions de Madame Charov, ce qui donnera lieu à cet article sans concessions, avec des mots durs, ceux-là même qui font l’objet d’une interdiction tacite dans les tribunaux où règne - en lieu et place de la protection des femmes et des enfants en cas de violences conjugales - la sacro-sainte « coparentalité » à laquelle même les pires violences (et les preuves de celles-ci) doivent se soumettre, avec les dérives que l’on connaît.</p>
            </div>
          </div>
          <div className='marche-articles-c'>
            <img className='marche-articles-c-img' src='https://www.aht.li/3648030/IMG_0722.jpg' alt='Article de presse'/>
            <div className='marche-articles-c-div'>
              <h3>Centre Presse, page 7, lundi 12 mars 2018, par Alain Laroche :</h3>
              <h4>Jusqu’au Ministère de la Justice</h4>
              <p>Elle s’appelle Corinne Elsass et, depuis le 28 février, au départ de Rochefort-sur-Mer, elle s’est engagée dans une marche, avec sa chienne Linès, contre le «désenfantement» et afin de porter le message du mouvement «Maman est debout» de la part de milliers de femmes en lutte contre la violence et la discrimination faites aux femmes et aux enfants.</p>
            </div>
          </div>
          <div className='marche-articles-d'>
            <img className='marche-articles-d-img' src='https://www.aht.li/3648031/La_Nouvelle_Republique_19_03_2018_P-8.jpeg' alt='Article de presse'/>
            <div className='marche-articles-d-div'>
              <h3>La Nouvelle République, page 8, lundi 19 mars 2018</h3>
              <h4>La marche d'une «maman debout»</h4>
              <p>Corinne, accompagnée de sa chienne, a fait halte à Chaumont-sur-Loire vendredi.</p>
            </div>
          </div>
          <div className='marche-articles-e'>
            <img className='marche-articles-e-img' src='https://www.aht.li/3648032/LHebdo_13_6_2019_P-14.jpeg' alt='Article de presse'/>
            <div className='marche-articles-e-div'>
              <h3>L'Hebdo (Charente-Maritime), page 14, jeudi 13 juin 2019, par Marie Ballet</h3>
              <h4>Tonnay-Charente</h4>
              <p>Initiatrice du mouvement Maman est debout, Corinne a entamé une marche à travers la France avec étapes en février 2018. Un mouvement symbolique destiné à alerter sur la situation des femmes victimes de violences conjugales sous toutes leurs formes et notamment le désenfantement.</p>
            </div>
          </div>
        </div>
    </div>
  );
};

Marche.propTypes = {
  //getInfo: PropTypes.func.isRequired,
};

export default Marche;
