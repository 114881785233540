import axios from 'axios';
import { GET_TEMOIGNAGES, storeTemoignages } from '../actions';
import apiBaseURL from './baseURL';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const api = axios.create({
  baseURL: apiBaseURL,
});

const temoignagesMiddleware = (store) => (next) => (action) => {
  //console.log(action.type)
  switch (action.type) {
    case GET_TEMOIGNAGES:
      api.get('/v1/temoignages').then((response) => {
        //console.log(response.data)
        store.dispatch(storeTemoignages(response.data));

      })
        .catch((error) => {
          console.error(error);
        });
      break;
      /*
    case SELECT_TEMOIGNAGE:
      const selectedTemoignage = state.temoignages.list.find( elem => elem.msg_id === action.selected).msg_content

      break;
      */
    default:
  }
  next(action);
};

export default temoignagesMiddleware;