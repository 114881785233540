/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import React from 'react';
import './style.scss';
//import { Link } from 'react-router-dom';

const ScrollText = () => {
    const phrases = [
        "Association de loi 1901",
        "Quand ils ne nous tuent pas, ils nous désenfantent",
        "Protéger la mère, c'est protéger l'enfant",
        "#metoodesenfantee",
        "Maman est Debout"
    ]
    let phrase = 'Association de loi 1901';
    setInterval(function() {
        document.querySelector('.scroll-wrap-inner').innerHTML = phrases[Math.floor(Math.random()*phrases.length)];
    }, 20*1000);

    return (
    <div className='scroll-wrap'>
        <div className='scroll-wrap-inner'>
            {phrase}
        </div>
    </div>
    );
};

ScrollText.propTypes = {

};

export default ScrollText;
