import { combineReducers } from 'redux';

//import filtersReducer from './filters';
//import usersReducer from './users';
//import teamReducer from './team';
//import siteReducer from './site';
//import videogameReducer from './videogame';
import homeReducer from './home';
import blogReducer from './blog';
import temoignagesReducer from './temoignages';
//import errorReducer from './error';

const rootReducer = combineReducers({
  //filters: filtersReducer,
  //users: usersReducer,
  //team: teamReducer,
  //site: siteReducer,
  home: homeReducer,
  blog: blogReducer,
  temoignages: temoignagesReducer,
  //videogame: videogameReducer,
  //error: errorReducer,
});

export default rootReducer;
