/* eslint-disable no-console */
import axios from 'axios';
import { GET_BLOG, storeBlog } from '../actions'; //
import apiBaseURL from './baseURL';
const api = axios.create({
    baseURL: apiBaseURL,
});

const blogMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case GET_BLOG:
            api.get('/v1/blog')
                .then((response) => {
                    console.log(response.data);
                    store.dispatch(storeBlog(response.data));
                })
                .catch((error) => {
                    console.error(error);
                });
            break;
        default:
            next(action);
    }
};

export default blogMiddleware;
