import {
    STORE_INFO,
} from '../actions';
const initialState = {
    accueil: []
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_INFO: {
      //console.log("a")
      //console.log(action.home[0])
      //console.log(state)
      return {
        ...state,
        accueil: action.home[0]
      };
    }
    default:
      return state;
  }
};

export default reducer;
