import React, { useEffect }  from 'react'; // , { useEffect } 
import { useSelector } from 'react-redux';
import Markdown from 'markdown-to-jsx';
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Blog = ({
  getBlog,
}) => {
  useEffect(getBlog, [getBlog]);
  //console.log(getInfo)

  const truc = useSelector(state => state.blog.blog)
  //console.log(truc)
  
  return (
    <div className='blog'>
      
      {truc.map((data, id) => {
        return <div className='blog-article' key={id}>
          <img className='blog-article-img' src={data.lien_img !== null ? data.lien_img:"https://sd-g1.archive-host.com/membres/images/217ec57e09aa71b32f143640630d380ad933ffb7/site/logo_med_transpa.png"} alt="img de l'article"/>
          <h1 className='blog-article-titre' ><Markdown>{data.titre}</Markdown></h1>
          <div className='blog-article-text'><Markdown>{data.article.replace(/␥/g, '"')}</Markdown></div>
        </div>
      })}
      
    </div>
  );
};

Blog.propTypes = {
  //getBlog: PropTypes.array.isRequired,
};

export default Blog;
