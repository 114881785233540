
export const GET_TEMOIGNAGES = 'GET_TEMOIGNAGES';
export const getTemoignages = () => ({
  type: GET_TEMOIGNAGES
});
export const STORE_TEMOIGNAGES = 'STORE_TEMOIGNAGES';
export const storeTemoignages = (data) => ({
  type: STORE_TEMOIGNAGES,
  list: data
});
export const SELECT_TEMOIGNAGE = 'SELECT_TEMOIGNAGE';
export const selectTemoignage = (data) => ({
  type: SELECT_TEMOIGNAGE,
  data
});

export const GET_INFO = 'GET_INFO';
export const getInfo = () => ({
  type: GET_INFO,
});
export const STORE_INFO = 'STORE_INFO';
export const storeInfo = (info) => ({
  type: STORE_INFO,
  home: info,
});

export const GET_BLOG = 'GET_BLOG';
export const getBlog = () => ({
  type: GET_BLOG,
});
export const STORE_BLOG = 'STORE_BLOG';
export const storeBlog = (info) => ({
  type: STORE_BLOG,
  blog: info
});