/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

const Temoignages = () => {
  const tem = useSelector(state => state.temoignages.list);
  const tem_selected = useSelector(state => state.temoignages.selected)
  const dispatch = useDispatch()
  tem.length === 0 && dispatch({ type: "GET_TEMOIGNAGES" })
  const displayArticle = async (event) => {
    const toDisp = tem.find( elem => elem.msg_id === event.target.id).msg_content
    dispatch({ type: "SELECT_TEMOIGNAGE", toDisp })
  };
  const displayText = async (event) => {
    if (event.target.parentElement.className === 'temoignages-content-left') {
      const toDisp = 'Découvrez les témoignages de la communauté !'
      dispatch({ type: "SELECT_TEMOIGNAGE", toDisp })
    }
    
  };
  return (
    <div className='temoignages'>
      <div className='temoignages-content'>
        <div className='temoignages-content-left'>
        {tem.map((data, id) => {
          return <div id={data.msg_id} onMouseOver={displayArticle} onClick={displayArticle} onMouseLeave={displayText} key={id} className='temoignages-content-left-item'>{data.msg_content.match(/(?=[^]{0,40})[^]{0,39}(?:\w\b|\W)/)+'...'}</div>
        })}
        </div>
        <div className='temoignages-content-right'>
          <p className='temoignages-content-right-text'>{tem_selected !== "" ? tem_selected : "Découvrez les témoignages de la communauté !" }</p>
        </div>
      </div>
    </div>
  );
};

Temoignages.propTypes = {

};
export default Temoignages;
