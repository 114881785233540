/* eslint-disable no-console */
import axios from 'axios';
import {GET_INFO, storeInfo} from '../actions'; //
import apiBaseURL from './baseURL';
//
//axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
//
const api = axios.create({
  baseURL: apiBaseURL,
});

const homeMiddleware = (store) => (next) => (action) => {
  //console.log('will dispatch', action)
  //console.log('action.home', action.home)
  switch (action.type) {
    case GET_INFO:
      //console.log("pouet")
      api.get('/v1/info')
        .then((response) => {
          //console.log(response.data)
          //console.log("response")
          //console.log(action)
          //console.log(action)
          store.dispatch(storeInfo(response.data));
          
        })
        .catch((error) => {
          console.error(error);
        });
      break;
    default:
      next(action);
  }
};
//
export default homeMiddleware;
//