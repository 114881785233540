import { connect } from 'react-redux';
import Blog from '../../components/App/Blog';//src/components/App
import { getBlog } from '../../actions'; //src/actions
// isConnected: state.users.isConnected,
//  tags: state.site.tags,
const mapStateToProps = (state) => ({
  blog: state.blog.blog//.articles
});
//const mapStateToProps = null;
// fetchAdventureGames: (id) => {
//   dispatch(fetchAdventureGames(id));
// },
// closeBurger: () => {
//   dispatch(closeBurger());
// },
const mapDispatchToProps = (dispatch) => ({
  getBlog: () => {
    dispatch(getBlog());
  },
});
 
export default connect(mapStateToProps, mapDispatchToProps)(Blog); 
