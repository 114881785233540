/* eslint-disable import/no-extraneous-dependencies */
import { Routes, Route } from 'react-router-dom'; // BrowserRouter, , Redirect, useLocation
import PropTypes from 'prop-types';
import React from 'react'; //, { useEffect }
/*
import Search from 'src/containers/Search';
import ProfilePage from 'src/containers/ProfilePage';
import Login from 'src/containers/Login';
import Subscribe from 'src/containers/Login/Subscribe';
import VideoGame from 'src/containers/VideoGame';
import About from 'src/containers/About';
*/
import Home from '../../containers/Home';//'./Home'; //src/containers/Home
import Blog from '../../containers/Blog';//'./Home'; //src/containers/Home
//import logo from 'src/assets/images/logo_rose_bleu.png';
import Navbar from './Navbar';
import Footer from './Footer';
import Error404 from './404';
import Temoignages from './Temoignages';
import Divers from './Divers';
import Marche from './Marche';
//import Blog from './Blog';
//import MentionLegales from './MentionLegales';

import './style.scss';
import Adherer from './Adherer';
//fetchTags, 
//fetchAdventureGames,
//isConnected, 
//closeBurger,
const App = ({
  isConnected,
}) => {
  //const location = useLocation();
  //useEffect(closeBurger, [location.pathname]);
  //useEffect(() => {
  //  fetchTags();
  //  fetchAdventureGames(18);
  //}, []);
  //useEffect(fetchTags, [isConnected]);
  const handleClick = () => {
    // closeBurger();
  };
  return (
    <div className="app" onClick={handleClick}>
      <Navbar />
      <Routes>
        {/* routes diverses
        <Route exact path="/mentions-legales" element={<Error404 />}/>
        <Route exact path="/contact" element={<Error404 />}/>
        <Route exact path="/statuts" element={<Statuts />}/>
        */}

        <Route exact path="/divers" element={<Divers />}/>
        <Route exact path="/blog" element={<Blog />}/>
        <Route exact path="/marche-de-2018" element={<Marche />}/>
        <Route exact path="/temoignages" element={<Temoignages />}/>
        <Route exact path="/boutique" element={<Error404 />}/>
        <Route exact path="/faire-un-don" element={<Error404 />}/>
        <Route exact path="/adherer" element={<Adherer />}/>
        <Route exact path="/" element={<Home />}/>
        <Route element={<Error404 />}/>
      </Routes> 
      <Footer />
    </div>
  );
};

App.propTypes = {
  getInfo: PropTypes.func.isRequired,
  //getBlog: PropTypes.func.isRequired,
  //fetchTags: PropTypes.func.isRequired,
  //fetchAdventureGames: PropTypes.func.isRequired,
  //isConnected: PropTypes.bool.isRequired,
  //closeBurger: PropTypes.func.isRequired,
};

export default App;
